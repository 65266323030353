<template>
  <div>
    <h2>Nieuwe factuur</h2>

    <form @submit.prevent="submit">
      <p>
        <label>
          Omschrijving<br>
          <input type="text" v-model="description" required />
        </label>
      </p>

      <p>
        <label>
          Factuurdatum<br>
          <input type="date"
                 data-format="yyyy-mm-dd"
                 @change="invoiceDate = $event.target.value"
                 required />
        </label>
      </p>

      <p>
        <label>
          Groep<br>
          <select v-model="groupId" required>
            <option value="">Kies een groep</option>
            <option :value="group.id" v-for="group in groups" :key="group.id">
              {{ group.name }}
            </option>
          </select>
        </label>
      </p>

      <template v-if="groupId">
        <h3>Tarieven</h3>

        <p v-for="role in roles" :key="role">
          <label>
            {{ role }}<br>
            <input type="text" @input="setTariff(role, $event)" required />
          </label>
        </p>

        <p>
          <button :disabled="isLoading">Aanmaken</button>
        </p>
      </template>
    </form>
  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  reactive,
  ref,
  watch,
} from 'vue';
import { useQuery, useMutation } from 'villus';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();
    const groups = ref([]);
    const roles = ref([]);
    const isLoading = ref(false);

    const variables = reactive({
      description: '',
      invoiceDate: '',
      groupId: '',
      tariffs: '{}',
    });

    const Groups = `
      query groups {
        groups {
          id
          name
          roles
        }
      }
    `;

    const { data: groupsResult } = useQuery({ query: Groups, cachePolicy: 'network-only' });

    const CreateInvoice = `
      mutation CreateInvoice($description: String!,
                             $groupId: ID!,
                             $invoiceDate: String!,
                             $tariffs: JSON!) {
        CreateInvoice(description: $description,
                      groupId: $groupId,
                      tariffs: $tariffs,
                      invoiceDate: $invoiceDate) {
          id
          downloadUrl
        }
      }
    `;

    const { data, execute } = useMutation(CreateInvoice);

    const submit = () => {
      isLoading.value = true;
      execute(variables);
    };

    const setTariff = (role, evt) => {
      const obj = JSON.parse(variables.tariffs);
      obj[role] = parseFloat(evt.target.value);
      variables.tariffs = JSON.stringify(obj);
    };

    watch(groupsResult, (result) => {
      groups.value = result.groups;
    });

    watch(data, () => {
      router.replace({ name: 'Invoices' });
    });

    watch(variables, ({ groupId }) => {
      const group = groups.value.filter((g) => g.id === groupId)[0];
      if (group) { roles.value = group.roles; }
    });

    return {
      submit,
      ...toRefs(variables),
      isLoading,
      roles,
      groups,
      setTariff,
    };
  },
});
</script>
